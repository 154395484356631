import { computed, reactive, ref } from "vue";
import standingsService from "@/services/StandingsService";

const standings = ref([]);

export const useStandings = () => {
  const isStandingsVisible = ref(false);
  const loaders = reactive({ standings: false });
  const filters = reactive({
    season: process.env.VUE_APP_ACTIVE_SEASON,
    phase: null,
    club: { id: 0, name: "All" },
  });

  const clubs = ref([]);
  const regionFilter = ref("all");

  const filteredStandings = computed(() => {
    if (filters.club.id !== 0) {
      return standings.value.filter((item) => {
        return item.team.club.id === filters.club.id;
      });
    }
    return standings.value;
  });

  const getStandings = async (competitionId, phaseId) => {
    loaders.standings = true;
    standings.value = await standingsService.getStandingsByCompetitionId(competitionId, phaseId);
    isStandingsVisible.value = true;
    clubs.value = [
      { id: 0, name: "All" },
      ...new Map(standings.value.map((item) => [item.team.club.id, item.team.club])).values(),
    ];
  };

  const filterTeams = (newFilters) => {
    regionFilter.value = newFilters?.club?.name?.toLowerCase();
    loaders.standings = false;
    Object.assign(filters, newFilters);

    if (newFilters.isStandingsVisible) {
      getStandings(newFilters.competitionId, newFilters.phase);
    } else {
      loaders.standings = true;
    }
  };

  const getStandingsByClubName = (standings, clubName) => {
    if (clubName !== "") {
      return standings.filter((item) => item.team.club.name === clubName);
    }
    return standings.value;
  };

  return {
    clubs,
    loaders,
    filters,
    regionFilter,
    filteredStandings,
    isStandingsVisible,
    filterTeams,
    getStandings,
    getStandingsByClubName,
  };
};
