<script setup>
import misc from "@/core/helpers/misc";
import { toRefs, defineProps } from "vue";

const props = defineProps({
  regionFilter: String,
  filteredStandings: Array,
  filters: Object,
  onHomePage: Boolean,
});

const { regionFilter, filteredStandings, filters } = toRefs(props);
</script>

<template>
  <table class="table table-bordered bnxt-table">
    <thead>
      <tr>
        <th style="text-align: center">#</th>
        <th style="text-align: center">Team</th>
        <th style="text-align: center">Games</th>
        <th style="text-align: center">WON</th>
        <th style="text-align: center">LOST</th>

        <th style="text-align: center" v-if="filters.season >= 2025">%</th>
        <th style="text-align: center" v-else>POINTS</th>
        <th style="text-align: center" v-if="!onHomePage">+/-</th>
        <th style="text-align: center" v-if="!onHomePage">DIFF</th>
        <th style="text-align: center" v-if="!onHomePage">HOME</th>
        <th style="text-align: center" v-if="!onHomePage">AWAY</th>
        <th style="text-align: center" v-if="!onHomePage">LAST-5</th>
        <th style="text-align: center" v-if="!onHomePage">STREAK</th>
      </tr>
    </thead>
    <tbody :class="`region-${regionFilter}`">
      <tr v-for="(row, index) in filteredStandings">
        <td>{{ index + 1 }}</td>
        <td class="team">
          <router-link
            :to="{
              name: misc.getRouteNameByLocale($router.options.routes, 'Team Statistics'),
              query: { team_id: row.team.id },
            }"
          >
            <div class="logo-wrapper">
              <div
                v-if="row?.team?.team_logo"
                :style="{ backgroundImage: `url(${row?.team?.team_logo?.public_url})` }"
                class="team-logo"
              ></div>
            </div>
            {{ row.team.name }}
          </router-link>
        </td>
        <td>{{ row.played }}</td>
        <td>{{ row.win }}</td>
        <td>{{ row.loss }}</td>
        <td>
          <b v-if="filters.season >= 2025">{{ row.percent }}</b>
          <b v-else>{{ row.point }}</b>
        </td>
        <td v-if="!onHomePage">{{ row.score }} - {{ row.against }}</td>
        <td v-if="!onHomePage">{{ row.score_difference }}</td>
        <td v-if="!onHomePage">{{ row.win_loss.home.win }} - {{ row.win_loss.home.loss }}</td>
        <td v-if="!onHomePage">{{ row.win_loss.away.win }} - {{ row.win_loss.away.loss }}</td>
        <td v-if="!onHomePage">{{ row.last5.win }} - {{ row.last5.loss }}</td>
        <td v-if="!onHomePage"><span v-if="!row.streak.is_win">-</span>{{ row.streak.count }}</td>
      </tr>
    </tbody>
  </table>
</template>

<style scoped lang="scss">
.region-netherlands {
  tr:nth-child(6) {
    border-bottom: 1px solid #f75f00;
  }
}

table thead tr th {
  &:first-child {
    min-width: 74px;
  }

  &:nth-child(2) {
    min-width: 270px;
  }

  &:nth-child(7),
  &:nth-child(11) {
    min-width: 80px;
  }
}

.team {
  max-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  position: relative;

  a {
    display: flex;
    align-items: center;
    height: calc(100% - 30px);
    width: 100%;
    position: absolute;
    top: 15px;
    left: 0;
  }

  .team-logo {
    width: 35px;
    height: 35px;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
  }

  img {
    margin-right: 10px;
  }
}

.silver-league-info {
  font-size: 10px;
}

.region-belgium {
  tr:nth-child(8) {
    border-bottom: 1px solid #f75f00;
  }
}
</style>
