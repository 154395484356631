import axios from "axios";
import router from "@/router";

export default function setup() {
  axios.interceptors.request.use(
    (config) => {
      config.headers["Content-Type"] = "application/json";
      config.headers["X-Authorization"] = process.env.VUE_APP_API_KEY;
      config.headers["X-Localization"] = localStorage.getItem("lang") || process.env.VUE_APP_I18N_LOCALE;

      if (config.url) {
        config.url += (config.url.includes("?") ? "&" : "?") + "lang=" + localStorage.getItem("lang");
      }

      return config;
    },
    (err) => {
      return Promise.reject(err);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    (err) => {
      console.log(err.response.status);
      switch (err.response.status) {
        case 500:
          //location.replace("/page-not-found");
          break;
        case 404:
          router.push({ name: "PageNotFound" });
          break;
      }
      return Promise.reject(err);
    }
  );
}
