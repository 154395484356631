<template>
  <div class="flex-container">
    <span>{{ $t("filter") }}</span>

    <div class="selects">
      <div class="select-wrapper">
        <label>{{ $t("season") }}</label>
        <select v-model="filters.season" @change="onChangeSeason">
          <option v-for="season in seasons" :value="season.season">
            {{ season.season - 1 }} - {{ season.season }}
          </option>
        </select>
      </div>

      <div class="select-wrapper" v-if="showMonthSelector">
        <label>{{ $t("month") }}</label>
        <select v-model="filters.month" @change="emitFilters">
          <option value="-1">{{ $t("all_months") }}</option>
          <option :value="1">{{ $t("months.january") }}</option>
          <option :value="2">{{ $t("months.february") }}</option>
          <option :value="3">{{ $t("months.march") }}</option>
          <option :value="4">{{ $t("months.april") }}</option>
          <option :value="5">{{ $t("months.may") }}</option>
          <option :value="6">{{ $t("months.june") }}</option>
          <option :value="7">{{ $t("months.july") }}</option>
          <option :value="8">{{ $t("months.august") }}</option>
          <option :value="9">{{ $t("months.september") }}</option>
          <option :value="10">{{ $t("months.october") }}</option>
          <option :value="11">{{ $t("months.november") }}</option>
          <option :value="12">{{ $t("months.december") }}</option>
        </select>
      </div>

      <div class="select-wrapper">
        <label>{{ $t("phase") }}</label>
        <select v-model="filters.phase" @change="emitFilters">
          <option :value="null" v-if="isOverall">{{ $t("overall") }}</option>
          <option v-for="phase in phases" @click="filters.phaseName = phase.name" :value="phase.id">
            {{ phase.name }}
          </option>
        </select>
      </div>

      <div class="select-wrapper">
        <label>{{ $t("region") }}</label>
        <select v-model="filters.club" @change="emitFilters">
          <option v-for="club in clubs" @click="filters.club.name = club.name" :value="club">
            {{ club.name }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, watch, defineProps, defineEmits } from "vue";
import seasonService from "@/services/SeasonService";
import phaseService from "@/services/PhaseService";

const props = defineProps({
  isStandings: { type: Boolean, default: false },
  isOverall: { type: Boolean, default: false },
  showMonthSelector: { type: Boolean, default: true },
  clubs: { type: Array },
});

const emit = defineEmits(["filters"]);

const seasons = ref([]);
const phasesComp = ref([]);
const phases = ref([]);
const filters = reactive({
  season: null,
  phase: null,
  phaseName: "",
  competitionId: null,
  month: -1,
  isStandingsVisible: false,
  club: { id: 0, name: "All" },
  clubName: "All",
});

const getSeasons = async () => {
  seasons.value = await seasonService.getSeasons();
  if (seasons.value.length) {
    filters.season = seasons.value[0].season;
    await getPhases(filters.season);
  }
};

const getPhases = async (season) => {
  phasesComp.value = await phaseService.getPhases(season, props.isStandings);
  if (phasesComp.value.length) {
    phases.value = phasesComp.value[0].phases;
    filters.phase = props.isOverall ? null : phases.value[0]?.id;
    filters.competitionId = phasesComp.value[0]?.competition?.id;
    filters.phaseName = phases.value[0]?.name || "";
    filters.isStandingsVisible = phasesComp.value[0]?.competition?.standings_visible || false;
    emitFilters();
  }
};

const emitFilters = () => {
  filters.phaseName = phases.value.find((phase) => phase.id === filters.phase)?.name || "";
  emit("filters", { ...filters });
};

watch(
  () => filters.season,
  (newSeason) => {
    if (newSeason) getPhases(newSeason);
  }
);

onMounted(getSeasons);
</script>

<style scoped lang="scss">
.flex-container {
  display: flex;
  align-items: flex-end;
  width: 100%;
  gap: 20px;

  span {
    font-family: DrukWideBoldItalic, Sans-serif;
    font-weight: 600;
    color: #000;
    width: 100px;
    font-size: 30px;
    text-transform: uppercase;
  }

  .selects {
    width: 100%;
    display: flex;
    gap: 20px;

    .select-wrapper {
      width: 48%;

      label {
        font-family: DrukWide;
        font-weight: 500;
        color: #000;
        font-size: 12px;
      }
    }
  }
}

@media only screen and (max-width: 640px) {
  .flex-container {
    flex-direction: column;
    align-items: flex-start;
    margin-top: 20px;
  }

  .selects {
    flex-direction: column;

    .select-wrapper {
      width: 100% !important;
    }
  }
}
</style>
