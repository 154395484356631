<template>
  <div class="home-standings">
    <h2 class="title">{{ $t("home.standings.title") }}</h2>

    <div class="flex-container">
      <div class="flex-item">
        <h3>{{ standings?.netherlands?.name }}</h3>
        <div class="table-wrapper">
          <table class="table table-bordered bnxt-table">
            <thead>
              <tr>
                <th style="text-align: center">#</th>
                <th style="text-align: center">Team</th>
                <th style="text-align: center">Games</th>
                <th style="text-align: center">WON</th>
                <th style="text-align: center">LOST</th>
                <th style="text-align: center">%</th>
                <th style="text-align: center">+/-</th>
                <th style="text-align: center">DIFF</th>
                <th style="text-align: center">HOME</th>
                <th style="text-align: center">AWAY</th>
                <th style="text-align: center">LAST-5</th>
                <th style="text-align: center">STREAK</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="row in standings?.netherlands?.standings">
                <td>{{ row.position }}</td>
                <td class="team">
                  <router-link
                    :to="{
                      name: misc.getRouteNameByLocale($router.options.routes, 'Team Statistics'),
                      query: { team_id: row.team.id },
                    }"
                  >
                    <div class="logo-wrapper">
                      <div
                        v-if="row?.team?.team_logo"
                        :style="{ backgroundImage: `url(${row?.team?.team_logo?.thumbnails.small})` }"
                        class="team-logo"
                      ></div>
                    </div>
                    {{ row.team.name }}
                  </router-link>
                </td>
                <td>{{ row.played }}</td>
                <td>{{ row.win }}</td>
                <td>{{ row.loss }}</td>
                <td>
                  <b>{{ row.percent }}</b>
                </td>
                <td>{{ row.score }} - {{ row.against }}</td>
                <td>{{ row.score_difference }}</td>
                <td>{{ row.win_loss.home.win }} - {{ row.win_loss.home.loss }}</td>
                <td>{{ row.win_loss.away.win }} - {{ row.win_loss.away.loss }}</td>
                <td>{{ row.last5.win }} - {{ row.last5.loss }}</td>
                <td><span v-if="!row.streak.is_win">-</span>{{ row.streak.count }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import misc from "@/core/helpers/misc";
export default {
  name: "home-standings",
  props: {
    standings: null,
  },
  data() {
    return {
      misc: misc,
    };
  },
};
</script>

<style scoped lang="scss">
.home-standings {
  padding: 1.5% 8% 0;

  h2 {
    color: #000;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 80px;
    font-weight: 600;
    text-transform: uppercase;
    line-height: 1.3em;
  }

  .flex-container {
    display: flex;
    gap: 20px;

    .flex-item {
      width: 100%;

      h3 {
        color: #000;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-size: 50px;
        font-weight: 600;
        text-transform: uppercase;
        line-height: 1.3em;
        display: inline-block;
        width: 100%;
        margin-bottom: 20px;
      }

      .silver-league-info {
        font-size: 10px;
        padding-inline: 10px;
      }

      .table-wrapper {
        table thead tr th {
          &:first-child {
            min-width: 74px;
          }
          &:nth-child(2) {
            min-width: 270px;
          }
        }

        .team {
          max-width: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          vertical-align: top;
          position: relative;

          a {
            display: flex;
            align-items: center;
            height: calc(100% - 30px);
            width: 100%;
            position: absolute;
            top: 15px;
            left: 0;
          }

          .team-logo {
            width: 35px;
            height: 35px;
            background-size: contain;
            background-position: center;
            background-repeat: no-repeat;
          }

          img {
            margin-right: 10px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  .home-standings {
    h2 {
      font-size: 50px;
    }

    .flex-container {
      .flex-item {
        h3 {
          font-size: 36px;
        }
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .home-standings {
    padding: 1.5% 20px 4%;

    .flex-container {
      flex-direction: column;

      .flex-item {
        width: 100%;
      }
    }
  }
}
</style>
