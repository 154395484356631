<template>
  <AppLoader v-if="!misc.isDataLoaded(loaders)" />

  <hero :title="$t('standings.title')" bg-image="bnx_slider_1-min-scaled.jpg" :is-tiny="true"></hero>
  <div class="content-wrapper">
    <div class="content">
      <h3>BNXT {{ filters.season - 1 }}-{{ filters.season }} {{ $t("standings.section.title") }}</h3>

      <div class="filter-wrapper">
        <TeamFilter :is-standings="true" :clubs="clubs" @filters="filterTeams($event)" :show-month-selector="false" />
      </div>

      <div class="table-wrapper" v-if="isStandingsVisible">
        <StandingsTable
          :region-filter="regionFilter"
          :filtered-standings="filteredStandings"
          :filters="filters"
        ></StandingsTable>
        <p v-if="filters.phaseName === 'Elite Silver'" class="silver-league-info">
          * The results of the BE-BE matchups in Elite Silver will be calculated into the overall standings, but in case
          of tied standings between two or more BE teams, only the results from the national phase are taken into
          consideration.
        </p>
        <p
          v-html="$t('standings_text')"
          v-if="filters.phaseName === 'Elite Silver' || filters.phaseName === 'Elite Gold'"
          class="silver-league-info"
        ></p>
      </div>
    </div>
  </div>
</template>

<script setup>
import TeamFilter from "@/components/simple/filters/team-filter/TeamFilter";
import Hero from "@/components/shared/hero";
import AppLoader from "@/components/shared/loader/AppLoader";
import misc from "@/core/helpers/misc";
import StandingsTable from "@/views/standings/StandingsTable.vue";
import { useStandings } from "@/compsables/useStandings";

const { filters, loaders, filterTeams, filteredStandings, regionFilter, isStandingsVisible, clubs } = useStandings();
</script>

<style scoped lang="scss">
.content-wrapper {
  background-color: #f2faff;

  .content {
    padding: 1.5% 8% 4%;

    h3 {
      color: #000;
      font-family: DrukWideBoldItalic, Sans-serif;
      font-size: 50px;
      font-weight: 600;
      text-transform: uppercase;
      line-height: 1.3em;
      display: inline-block;
      width: 100%;
      margin-bottom: 20px;
    }

    .table-wrapper {
      margin-top: 85px;
    }

    .filter-wrapper {
      width: 48%;
    }
  }
}

@media only screen and (max-width: 1150px) {
  .content-wrapper {
    .content {
      padding: 1.5% 20px 4%;

      .filter-wrapper {
        width: 52%;
      }
    }
  }
}

@media only screen and (max-width: 980px) {
  .content-wrapper {
    .content {
      .filter-wrapper {
        width: 100%;
      }
    }
  }
}
</style>
