<template>
  <AppLoader v-if="!misc.isDataLoaded(loaders)" />

  <hero
    :title="$t('statistics')"
    :subtitle="
      $t('In order to qualify for statistics leaderboards, players must have played more than half of the games.')
    "
    bg-image="bnx_slider_5-min-scaled.jpg"
    :is-tiny="true"
  ></hero>

  <div class="tabs-wrapper">
    <ul class="tabs">
      <li :class="{ active: activeTab === 1 }">
        <router-link
          :to="{
            name: misc.getRouteNameByLocale($router.options.routes, 'Statistics'),
            hash: '#statistics-individual',
          }"
        >
          {{ $t("statistics.individual") }}
        </router-link>
      </li>
      <li :class="{ active: activeTab === 2 }">
        <router-link :to="{ name: misc.getRouteNameByLocale($router.options.routes, 'Statistics'), hash: '#teams' }">
          {{ $t("statistics.teams") }}
        </router-link>
      </li>
      <li :class="{ active: activeTab === 3 }">
        <router-link
          :to="{ name: misc.getRouteNameByLocale($router.options.routes, 'Statistics'), hash: '#total-teams' }"
        >
          {{ $t("statistics.total_teams") }}
        </router-link>
      </li>
    </ul>
  </div>

  <div class="content">
    <IndividualStatistics v-if="activeTab === 1" />
    <TeamsStatistics :competition="teamCompetitions" v-if="activeTab === 2" />
    <TotalTeamsStatistics v-if="activeTab === 3" />
  </div>
</template>

<script>
import Hero from "@/components/shared/hero";
import IndividualStatistics from "@/views/statistics/individual-statistics";
import TeamsStatistics from "@/views/statistics/teams-statistics";
import TotalTeamsStatistics from "@/views/statistics/total-teams-statistics";
import AppLoader from "@/components/shared/loader/AppLoader";
import sportService from "@/services/SportService";
import misc from "@/core/helpers/misc";
import phaseService from "@/services/PhaseService";
export default {
  name: `Statistics`,
  components: { AppLoader, Hero, IndividualStatistics, TeamsStatistics, TotalTeamsStatistics },

  data() {
    return {
      activeTab: 1,
      teamCompetitions: {},
      loaders: {
        teamStatistics: false,
      },
      misc: misc,
    };
  },

  mounted() {
    /*const routeTagsLength = this.$route.path.split("/").length;
    const lastUrlTag = this.$route.path.split("/")[routeTagsLength - 1];*/

    const hash = this.$route.hash;
    this.setActiveTabByHash(hash);
    this.getTeamCompetitions();
  },

  methods: {
    onChangeTab(tabId) {
      this.activeTab = tabId;
    },

    setActiveTabByHash(hash) {
      switch (hash) {
        case "#statistics-individual":
        default:
          this.activeTab = 1;
          break;
        case "#teams":
          this.activeTab = 2;
          break;
        case "#total-teams":
          this.activeTab = 3;
          break;
      }
    },

    async getTeamCompetitions() {
      await phaseService.getPhases(process.env.VUE_APP_ACTIVE_SEASON).then(async (res) => {
        this.teamCompetitions = await sportService.getTeamCompetitions(res[0].competition.id);
        this.loaders.teamStatistics = true;
      });
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  background-color: #f2faff;
  padding: 74px 8% 4% 8%;
}

.tabs-wrapper {
  position: relative;
  top: -27px;
  padding: 0 8%;
  margin-bottom: -54px;

  .tabs {
    padding: 0;
    list-style: none;
    background: #fff;
    border-radius: 25px;
    margin: 0 auto;
    max-width: 100%;
    float: none;
    display: inline-block;
    box-shadow: 0 10px 5px #def3ff;

    li {
      display: inline-block;
      font-family: UnboundedBold, Sans-serif;
      font-weight: 600;
      font-size: 16px;
      padding: 10px 40px;
      border-radius: 50px;
      margin: -1px;
      text-transform: uppercase;

      a {
        color: #003c80;
        text-decoration: none;
      }

      &.active {
        background: #37d69e;

        a {
          color: #fff;
        }
      }
    }
  }
}

@media only screen and (max-width: 1150px) {
  .content {
    padding: 74px 20px 4% !important;
  }

  .tabs-wrapper {
    padding: 0 20px;
  }
}

@media only screen and (max-width: 980px) {
  .content {
    padding: 74px 20px 70px;
  }

  .tabs-wrapper {
    text-align: center;

    .tabs {
      li {
        font-size: 20px;
      }
    }
  }
}
</style>
