<template>
  <div class="hero">
    <div class="container">
      <div class="report-banner-left player-name">
        <h4>
          <img
            v-if="player?.competition_team.team_logo"
            id="team_logo"
            :src="player?.competition_team.team_logo.public_url"
          />
          <div id="team_name">
            <span>{{ player?.competition_team.name }}</span>
          </div>
        </h4>
        <h2 id="player_name_number">
          <span id="f_name">{{ player?.player.first_name }}</span> <br />
          <span id="family_name">
            {{ player?.player.last_name?.toUpperCase() }}
          </span>
          <span id="shirt_number"> {{ player?.jersey }}</span>
        </h2>
      </div>

      <div class="player-image">
        <img
          id="player_score"
          :src="player.player.profile_image.thumbnails.medium"
          v-if="player?.player?.profile_image?.thumbnails?.medium"
        />
        <img id="player_score" src="../../assets/img/image_not_found.png" v-else />
      </div>
      <div class="report-banner-right">
        <div class="total-points-banner">
          <div class="total-points-banner">
            <ul>
              <li>
                <span class="total-points-label">Mins</span
                ><span class="total-points-number">{{ misc.formatNumber(playerOverallStats.minutes) }}</span>
              </li>
              <li>
                <span class="total-points-label">Points</span
                ><span class="total-points-number">{{ misc.formatNumber(playerOverallStats.points) }}</span>
              </li>
              <li>
                <span class="total-points-label">Rebounds</span
                ><span class="total-points-number">{{ misc.formatNumber(playerOverallStats.rebounds) }}</span>
              </li>
              <li>
                <span class="total-points-label">Assists</span
                ><span class="total-points-number">{{ misc.formatNumber(playerOverallStats.assists) }}</span>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="content">
    <h4 class="title">{{ $t("player.career_season_title") }}</h4>
    <div class="table-wrapper">
      <table class="table table-bordered bnxt-table career-season-averages">
        <thead>
          <tr>
            <th colspan="5">&nbsp;</th>
            <th colspan="2">GAME</th>
            <th colspan="3">2P</th>
            <th colspan="3">3P</th>
            <th colspan="3">FG</th>
            <th colspan="3">FT</th>
            <th colspan="3">REBOUNDS</th>
            <th colspan="2">FOULS</th>
            <th colspan="3">&nbsp;</th>
            <th colspan="2">BLOCKS</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th class="border-right-0">YEAR</th>
            <th class="border-left-0 border-right-0">NAME</th>
            <th class="border-left-0 border-right-0">TEAM</th>
            <th class="border-left-0 border-right-0">PTS</th>
            <th class="border-title border-left-0">MIN</th>
            <th class="border-right-0">G</th>
            <th class="border-title border-left-0">GS</th>
            <th class="border-right-0">2PM</th>
            <th class="border-left-0 border-right-0">2PA</th>
            <th class="border-title border-left-0">2P%</th>
            <th class="border-left-0 border-right-0">3PM</th>
            <th class="border-left-0 border-right-0">3PA</th>
            <th class="border-title border-left-0">3P%</th>
            <th class="border-left-0 border-right-0">FGM</th>
            <th class="border-left-0 border-right-0">FGA</th>
            <th class="border-title border-left-0">FG%</th>
            <th class="border-left-0 border-right-0">FTM</th>
            <th class="border-left-0 border-right-0">FTA</th>
            <th class="border-title border-left-0">FT%</th>
            <th class="border-left-0 border-right-0">DR</th>
            <th class="border-left-0 border-right-0">OR</th>
            <th class="border-title border-left-0">TOT</th>
            <th class="border-left-0 border-right-0">FP</th>
            <th class="border-title border-left-0">DF</th>
            <th class="border-left-0 border-right-0">AST</th>
            <th class="border-left-0 border-right-0">ST</th>
            <th class="border-title border-left-0">TO</th>
            <th class="border-left-0 border-right-0">BS</th>
            <th class="border-title border-left-0">BR</th>
            <th class="border-left-0 border-right-0">EFF</th>
          </tr>
        </thead>
        <tbody id="player_carrer_table" name="player_carrer_table">
          <tr v-for="row in playerCareerAverages">
            <td>{{ row?.competition.season - 1 }} - {{ row?.competition.season }}</td>
            <td>{{ row?.competition.name }}</td>
            <td>{{ row?.team.short_name }}</td>
            <td>{{ misc.formatNumber(row.points) }}</td>
            <td>{{ misc.formatNumber(row.minute) }}</td>
            <td>{{ misc.formatNumber(row.played_games) }}</td>
            <td>{{ misc.formatNumber(row.starter) }}</td>

            <td>{{ misc.formatNumber(row.dunk_made + row.two_point_made) }}</td>
            <td>{{ misc.formatNumber(row.dunk_all + row.two_point_all) }}</td>
            <td>
              {{ misc.formatNumber(((row.dunk_made + row.two_point_made) / (row.dunk_all + row.two_point_all)) * 100) }}
            </td>

            <td>{{ misc.formatNumber(row.three_point_made) }}</td>
            <td>{{ misc.formatNumber(row.three_point_all) }}</td>
            <td>{{ misc.formatNumber(row.three_point_percent) }}</td>

            <td>{{ misc.formatNumber(row.dunk_made + row.two_point_made + row.three_point_made) }}</td>
            <td>{{ misc.formatNumber(row.dunk_all + row.two_point_all + row.three_point_all) }}</td>
            <td>
              {{
                misc.formatNumber(
                  ((row.dunk_made + row.two_point_made + row.three_point_made) /
                    (row.dunk_all + row.two_point_all + row.three_point_all)) *
                    100,
                  true
                )
              }}
            </td>

            <td>{{ misc.formatNumber(row.free_throw_made) }}</td>
            <td>{{ misc.formatNumber(row.free_throw_all) }}</td>
            <td>{{ misc.formatNumber(row.free_throw_percent) }}</td>

            <td>{{ misc.formatNumber(row.defensive_rebound) }}</td>
            <td>{{ misc.formatNumber(row.offensive_rebound) }}</td>
            <td>{{ misc.formatNumber(row.total_rebound) }}</td>
            <td>{{ misc.formatNumber(row.foul) }}</td>
            <td>{{ misc.formatNumber(row.defensive_foul) }}</td>
            <td>{{ misc.formatNumber(row.assist) }}</td>
            <td>{{ misc.formatNumber(row.steal) }}</td>
            <td>{{ misc.formatNumber(row.turnover) }}</td>
            <td>{{ misc.formatNumber(row.block) }}</td>
            <td>{{ misc.formatNumber(row.block_against) }}</td>
            <td>{{ misc.formatNumber(row.value) }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <h4 class="title">{{ $t("player.game_statistics_title") }}</h4>
    <div class="table-wrapper">
      <table class="table table-bordered match-statistics bnxt-table">
        <thead>
          <tr>
            <th colspan="5">&nbsp;</th>
            <th colspan="3">2P</th>
            <th colspan="3">3P</th>
            <th colspan="3">FG</th>
            <th colspan="3">FT</th>
            <th colspan="3">REBOUNDS</th>
            <th colspan="2">FOULS</th>
            <th colspan="3">&nbsp;</th>
            <th colspan="2">BLOCKS</th>
            <th>&nbsp;</th>
          </tr>
          <tr>
            <th class="border-left-0 border-right-0">GAME DATE</th>
            <th class="border-left-0 border-right-0">GAME</th>
            <th class="border-left-0 border-right-0">RESULT</th>
            <th class="border-left-0 border-right-0">PTS</th>
            <th class="border-title border-left-0">MIN</th>
            <th class="border-right-0">2PM</th>
            <th class="border-left-0 border-right-0">2PA</th>
            <th class="border-title border-left-0">2P%</th>
            <th class="border-left-0 border-right-0">3PM</th>
            <th class="border-left-0 border-right-0">3PA</th>
            <th class="border-title border-left-0">3P%</th>
            <th class="border-left-0 border-right-0">FGM</th>
            <th class="border-left-0 border-right-0">FGA</th>
            <th class="border-title border-left-0">FG%</th>
            <th class="border-left-0 border-right-0">FTM</th>
            <th class="border-left-0 border-right-0">FTA</th>
            <th class="border-title border-left-0">FT%</th>
            <th class="border-left-0 border-right-0">DR</th>
            <th class="border-left-0 border-right-0">OR</th>
            <th class="border-title border-left-0">TOT</th>
            <th class="border-left-0 border-right-0">FP</th>
            <th class="border-title border-left-0">DF</th>
            <th class="border-left-0 border-right-0">AST</th>
            <th class="border-left-0 border-right-0">ST</th>
            <th class="border-title border-left-0">TO</th>
            <th class="border-left-0 border-right-0">BS</th>
            <th class="border-title border-left-0">BR</th>
            <th class="border-left-0 border-right-0">EFF</th>
          </tr>
        </thead>
        <tbody id="match_stats_table" name="match_stats_table">
          <tr v-for="row in playerSeasonGames">
            <td>{{ moment(row.game.game_time).format("DD-MMM-YYYY") }}</td>
            <td v-if="row.game.competitors[0].competition_team.name === player.competition_team.name">
              <span v-if="row.game.competitors[0].side === 1">vs.</span>
              <span v-else>@</span>
              {{ row.game.competitors[1].competition_team.name }}
            </td>
            <td v-else-if="row.game.competitors[1].competition_team.name === player.competition_team.name">
              <span v-if="row.game.competitors[1].side === 1">vs.</span>
              <span v-else>@</span>
              {{ row.game.competitors[0].competition_team.name }}
            </td>
            <td v-else>
              <span v-if="getOpponentCompetitionTeam(row).side === 1">vs.</span>
              <span v-else>@</span>
              {{ getOpponentCompetitionTeam(row).competition_team.name }}
            </td>
            <td>{{ row.game.competitors[0].finalScore }} - {{ row.game.competitors[1].finalScore }}</td>
            <td>{{ misc.formatNumber(row.points) }}</td>
            <td>{{ misc.formatNumber(row.minute) }}</td>
            <td>{{ misc.formatNumber(row.dunk_made + row.two_point_made) }}</td>
            <td>{{ misc.formatNumber(row.dunk_all + row.two_point_all) }}</td>
            <td>
              {{ misc.formatNumber(((row.dunk_made + row.two_point_made) / (row.dunk_all + row.two_point_all)) * 100) }}
            </td>

            <td>{{ misc.formatNumber(row.three_point_made) }}</td>
            <td>{{ misc.formatNumber(row.three_point_all) }}</td>
            <td>{{ misc.formatNumber(row.three_point_percent) }}</td>

            <td>{{ misc.formatNumber(row.dunk_made + row.two_point_made + row.three_point_made) }}</td>
            <td>{{ misc.formatNumber(row.dunk_all + row.two_point_all + row.three_point_all) }}</td>
            <td>
              {{
                misc.formatNumber(
                  ((row.dunk_made + row.two_point_made + row.three_point_made) /
                    (row.dunk_all + row.two_point_all + row.three_point_all)) *
                    100,
                  true
                )
              }}
            </td>

            <td>{{ misc.formatNumber(row.free_throw_made) }}</td>
            <td>{{ misc.formatNumber(row.free_throw_all) }}</td>
            <td>{{ misc.formatNumber(row.free_throw_percent) }}</td>

            <td>{{ misc.formatNumber(row.defensive_rebound) }}</td>
            <td>{{ misc.formatNumber(row.offensive_rebound) }}</td>
            <td>{{ misc.formatNumber(row.total_rebound) }}</td>
            <td>{{ misc.formatNumber(row.foul) }}</td>
            <td>{{ misc.formatNumber(row.defensive_foul) }}</td>
            <td>{{ misc.formatNumber(row.assist) }}</td>
            <td>{{ misc.formatNumber(row.steal) }}</td>
            <td>{{ misc.formatNumber(row.turnover) }}</td>
            <td>{{ misc.formatNumber(row.block) }}</td>
            <td>{{ misc.formatNumber(row.block_against) }}</td>
            <td>{{ misc.formatNumber(row.value) }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import playersService from "@/services/PlayersService";
import moment from "moment/moment";
import misc from "@/core/helpers/misc";
export default {
  name: `PlayerProfile`,
  data() {
    return {
      playerCareerAverages: null,
      playerSeasonGames: null,
      player: null,
      playerOverallStats: {},
      misc: misc,
      loaders: {
        playerCareerAverages: false,
        playerSeasonGames: false,
        player: false,
      },
    };
  },
  computed: { moment: () => moment },
  mounted() {
    window.scrollTo(0, 0);
    this.getPlayerCareerAverages(this.$route.query.player_id);
    this.getPlayerSeasonGames(this.$route.query.player_id);
    this.getRosterByRosterId(this.$route.query.player_id);
  },

  methods: {
    async getPlayerCareerAverages(rosterId) {
      this.playerCareerAverages = await playersService.getPlayerCareerAverages(rosterId);
      this.playerOverallStats = playersService.getPlayerOverallStats(this.playerCareerAverages);
      this.loaders.playerCareerAverages = true;
    },

    async getPlayerSeasonGames(rosterId) {
      this.playerSeasonGames = await playersService.getPlayerSeasonGames(rosterId);
      this.loaders.playerSeasonGames = true;
    },

    getOpponentCompetitionTeam(row) {
      return row.game.competitors.find((competitior) => competitior.competition_team.id !== row.competition_team_id);
    },

    async getRosterByRosterId(rosterId) {
      this.player = await playersService.getRosterByRosterId(rosterId);
      this.loaders.player = true;
    },
  },
};
</script>

<style scoped lang="scss">
.hero {
  background-color: #000;
  background-image: url("../../assets/img/bnx-player-bg-min.png");
  background-size: 900px;
  background-repeat: no-repeat;
  background-position: bottom left 77%;
  min-height: 550px;
  padding: 8% 8% 0 8%;
  position: relative;
  overflow: hidden;

  .container {
    padding-top: 50px;
    margin: 0;
    padding-left: 0;
    padding-right: 0;

    .player-name {
      margin-top: 60px;

      h4 {
        font-size: 26px;
        text-transform: uppercase;
        line-height: 30px;
        font-weight: 700;
        color: #fff;
        display: flex;
        align-items: center;
        margin: 0 0 14px;
        padding: 0;

        #team_logo {
          width: 30px;
          border-radius: 15px;
          margin-right: 10px;
        }

        #team_name {
          span {
            font-family: DrukWide;
            font-weight: 400;
            font-size: 19px;
            line-height: 25px;
            padding-right: 50px;
          }
        }
      }

      #player_name_number {
        line-height: 55px;
        font-size: 65px;
        font-family: DrukWideBoldItalic, Sans-serif;
        font-weight: 600;
        text-transform: uppercase;
        color: #fff;
        margin: 0;
        padding: 0;
        margin-top: 10px;

        #f_name {
          font-size: 38px;
          line-height: 38px;
        }

        #shirt_number {
          font-size: 90px;
          margin-left: 20px;
        }
      }
    }

    .report-banner-left {
      float: left;
      width: 40%;
    }

    .player-image {
      img {
        width: 400px;
        top: 172px;
        position: absolute;
        left: 0;
        right: 0;
        margin: 0 auto;
      }
    }

    .report-banner-right {
      position: unset;
      right: 0;
      float: right;
      width: 60%;
      color: #fff;

      .total-points-banner {
        position: absolute;
        max-width: 400px;
        width: 100%;
        bottom: 50px;
        top: auto;
        right: 10%;

        ul {
          list-style-type: none;
          padding: 0;
          margin: 0;

          li {
            width: calc(50% - 17px);
            float: left;
            background-color: rgb(255 255 255/30%);
            margin-right: 17px;
            margin-bottom: 17px;
            border-radius: 15px;
            padding: 12px;
            height: 90px;

            .total-points-label {
              display: inline-block;
              width: 100%;
              text-align: left;
              font-family: DrukWideBoldItalic, Sans-serif;
              font-size: 22px;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              color: #fff;
              line-height: 27px;
            }

            .total-points-number {
              font-family: DrukWide;
              font-size: 20px;
              font-weight: 500;
              text-transform: uppercase;
              letter-spacing: 0.5px;
              color: #fff;
              display: block;
              width: 100%;
              text-align: right;
              line-height: 60px;
            }
          }
        }
      }
    }
  }
}

.content {
  padding: 50px 8% 8%;

  .title {
    text-transform: uppercase;
    font-family: DrukWideBoldItalic, Sans-serif;
    font-size: 28px;
    margin: 40px 0 30px;
  }

  table.career {
    text-align: center;

    thead {
      tr {
        border: 1px solid;

        &:nth-child(2) {
          th {
            border-radius: 0 !important;
          }
        }
      }
    }
  }

  table.career-season-averages {
    thead {
      tr {
        border: 1px solid;

        &:nth-child(2) {
          th {
            border-radius: 0 !important;
          }
        }
      }
    }
  }

  table.match-statistics {
    text-align: center !important;

    thead {
      tr {
        border: 1px solid;

        &:nth-child(2) {
          th {
            border-radius: 0 !important;
          }
        }
      }
    }

    tbody {
      tr {
        td {
          text-align: center;
        }
      }
    }
  }
}

@media screen and (max-width: 1150px) {
  .hero {
    padding: 8% 20px;

    .container {
      .player-image img {
        width: 300px;
      }

      .report-banner-right {
        .total-points-banner {
          right: 0;
        }
      }
    }
  }
}

@media screen and (max-width: 980px) {
  .hero {
    padding-bottom: 0;
    min-height: 700px;
    height: auto;

    .container {
      max-width: 100%;

      .player-name {
        h4 {
          #team_name {
            span {
              font-size: 16px;
            }
          }
        }
      }

      .report-banner-left {
        width: 100%;
      }

      .player-image {
        text-align: center;

        img {
          width: 170px;
          position: unset;
        }
      }

      .report-banner-right {
        margin-top: 0;
        width: 100%;
        text-align: center;

        .total-points-banner {
          width: 100%;
          text-align: center;
          max-width: 100%;
          position: unset;
          margin-top: 25px;

          ul {
            display: block;
            margin: 0 auto;
            max-width: 500px;
            width: 100%;

            li {
              width: calc(25% - 10px);
              margin-bottom: 10px;
              margin-right: 10px;
              padding: 6px;
              height: 80px;

              .total-points-label {
                font-size: 18px;
              }

              .total-points-number {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  .hero {
    .container {
      .player-name {
        #player_name_number {
          line-height: 45px;
          font-size: 50px;

          #f_name {
            font-size: 30px;
            line-height: 25px;
          }

          #shirt_number {
            font-size: 75px;
          }
        }
      }
    }
  }
}
</style>
