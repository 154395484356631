<template>
  <footer>
    <div class="upper-footer-wrapper">
      <div class="left-footer">
        <img src="../../assets/img/A-BNXT_League_white.png" alt="" class="img-fluid" />
        <p>{{ $t("footer.left_block") }}</p>
        <div class="social">
          <a href="https://www.facebook.com/BNXTLeague" target="_blank">
            <img src="../../assets/img/social/bnx_logo_fb.svg" alt="Facebook" class="img-fluid" />
          </a>
          <a href="https://www.instagram.com/BNXTLeague/" target="_blank">
            <img src="../../assets/img/social/bnx_logo_ig.svg" alt="Instagram" class="img-fluid" />
          </a>
          <a href="https://www.youtube.com/channel/UC4QmIGx2Wa5_hiwlzK7HLTQ" target="_blank">
            <img src="../../assets/img/social/bnx_logo_youtube.svg" alt="YouTube" class="img-fluid" />
          </a>
          <a href="https://www.linkedin.com/company/bnxtleague" target="_blank">
            <img src="../../assets/img/social/bnx_logo_linkedin-new.svg" alt="LinkedIn" class="img-fluid" />
          </a>
          <a href="https://twitter.com/BNXTLeague" target="_blank">
            <img src="../../assets/img/social/bnx_logo_twitter.svg" alt="Twitter" class="img-fluid" />
          </a>
          <a href="https://www.tiktok.com/@bnxtleague" target="_blank">
            <img src="../../assets/img/social/bnx_logo_tiktok.svg" alt="Tiktok" class="img-fluid" />
          </a>
        </div>
      </div>
      <div class="center-footer">
        <div class="container-fluid">
          <div class="row">
            <div class="col-6">
              <h4 class="footer-group-title">{{ $t("footer.explore_bnxt") }}</h4>
              <ul>
                <li v-for="item in exploreBnxtItems">
                  <router-link
                    :to="{
                      name: misc.getRouteNameByLocale($router.options.routes, item.name),
                      params: {
                        locale: $i18n.locale,
                      },
                    }"
                    v-if="item.type === 'internal_url'"
                  >
                    {{ item.name }}
                  </router-link>
                  <a :href="item.link" target="_blank" v-if="item.type === 'external_url'">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
            <div class="col-6">
              <h4>{{ $t("footer.legal") }}</h4>
              <ul>
                <li v-for="item in legalItems">
                  <router-link
                    :to="{
                      name: misc.getRouteNameByLocale($router.options.routes, 'Static page'),
                      params: {
                        locale: $i18n.locale,
                        staticPage: item.slug,
                      },
                    }"
                    v-if="item.type === 'static_page'"
                  >
                    {{ item.name }}
                  </router-link>

                  <a :href="item.link" target="_blank" v-if="item.type === 'external_url'">
                    {{ item.name }}
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="right-footer">
        <h4>{{ $t("footer.right_block.title") }}</h4>
        <p>{{ $t("footer.right_block.subtitle") }}</p>
        <form @submit="submitForm($event)">
          <div class="form-group">
            <label for="email" class="email-label">{{ $t("email_address") }}</label>
            <input
              type="text"
              name="email"
              id="email"
              pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}"
              required
              v-model="email"
            />
          </div>

          <div class="form-group checkbox-wrapper">
            <input type="checkbox" id="privacy-policy" name="privacy-policy" required v-model="privacy_policy" />
            <label for="privacy-policy" class="checkbox-label">
              {{ $t("accept_privacy_policy") }}
            </label>
          </div>

          <div class="form-group checkbox-wrapper">
            <input type="checkbox" id="marketing" name="marketing" v-model="marketing" />
            <label for="marketing" class="checkbox-label">
              {{ $t("subscribe_marketing_emails") }}
            </label>
          </div>

          <button type="submit" id="submit-btn">{{ $t("submit") }}</button>
        </form>
      </div>
    </div>
    <div class="lower-footer">
      <p>&copy; 2021 BNXT, LLC. {{ $t("footer.all_rights_reserved") }}</p>
      <span>
        {{ $t("footer.powered_by") }}
        <a href="https://webpont.com" target="_blank">Webpont</a>
      </span>
    </div>
  </footer>

  <Snackbar @close="snackBarMessage = null" v-if="snackBarMessage">{{ snackBarMessage }}</Snackbar>
</template>

<script>
import { useReCaptcha } from "vue-recaptcha-v3";
import newsletterService from "@/services/NewsletterService";
import misc from "@/core/helpers/misc";
import Snackbar from "@/components/shared/snackbar";

export default {
  name: "app-footer",
  components: { Snackbar },
  props: {
    exploreBnxtItems: null,
    legalItems: null,
  },
  data() {
    return {
      email: null,
      marketing: false,
      privacy_policy: false,
      misc: misc,
      snackBarMessage: null,
    };
  },

  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();

    const recaptcha = async () => {
      // (optional) Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "subscribe".
      const token = await executeRecaptcha("subscribe");

      // Do stuff with the received token.
      return token;
    };

    return {
      recaptcha,
    };
  },

  methods: {
    async subscribeToNewsletter() {
      const data = {
        email: this.email,
        marketing_subscription: this.marketing ? "active" : "inactive",
      };
      await newsletterService.subscribeToNewsletter(data).then((res) => {
        if (res.data.email) {
          this.snackBarMessage = "You have successfully subscribed to our newsletter";
        } else {
          this.snackBarMessage = "You already have an active subscription";
        }
      });
    },

    async submitForm(e) {
      e.preventDefault();

      // If captcha has token
      if (await this.recaptcha()) {
        await this.subscribeToNewsletter();

        // Set everything to default
        this.email = null;
        this.marketing = false;
        this.privacy_policy = false;
      } else {
        this.snackBarMessage = "Captcha token is missing";
      }
    },
  },
};
</script>

<style scoped lang="scss">
footer {
  background: #000;
  color: #fff;

  h4 {
    color: #fff;
    font-family: UnboundedBold, Sans-serif;
    font-weight: 600;
    font-size: 20px;
    text-transform: uppercase;
    line-height: 1.3em;
  }

  .upper-footer-wrapper {
    display: flex;
    border-top: 1px solid #fff;

    .left-footer {
      width: 30%;
      padding: 85.672px 57px 0;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;

      img {
        max-width: 200px;
      }

      p {
        text-align: center;
        color: #fff;
        font-size: 14px;
        font-weight: 500;
        line-height: 1.3em;
        margin: 0 50px 20px;
      }

      .social {
        display: flex;
        justify-content: center;
        gap: 20px;

        img {
          max-width: 30px;
        }
      }
    }

    .center-footer {
      width: 30%;
      border-left: 1px solid #fff;
      border-right: 1px solid #fff;

      .col-6 {
        padding: 50px 40px;
      }

      ul {
        list-style: none;
        padding: 0;

        li {
          margin-bottom: 10px;

          a {
            color: #fff;
            text-decoration: none;
          }
        }
      }
    }

    .right-footer {
      width: 40%;
      padding: 50px 100px 50px 50px;

      p {
        margin-bottom: 35px;
      }

      form {
        .email-label {
          color: #fff;
          margin-bottom: 10px;
          font-family: DrukWide;
          font-weight: 400 !important;
          letter-spacing: 0.5px;
        }

        .checkbox-wrapper {
          display: flex;
          align-items: baseline;
          margin-bottom: 10px;

          .checkbox-label {
            font-family: helvetica;
            font-size: 18px;
            margin-left: 5px;
          }
        }

        #email {
          width: 100%;
          padding: 15px;
          font-family: helvetica;
          font-weight: 600;
          font-size: 18px;
          border-radius: 8px;
          color: #6441ff;
          box-shadow: 5px 5px 0 #f75f00;
          border: solid 3px #f75f00;
          margin-bottom: 30px;
        }

        #submit-btn {
          margin-bottom: 8px;
          margin-top: 15px;
          font-family: DrukWide;
          color: #fff;
          background-color: #f75f00;
          border-radius: 100px;
          border: none;
          padding: 20px 30px;
          font-size: 20px;
        }
      }
    }
  }

  .lower-footer {
    display: flex;
    justify-content: space-between;
    font-family: DrukWide;
    font-size: 12px;
    padding: 17px 35px;
    border-top: 1px solid #fff;
    text-align: center;

    p {
      font-weight: 400;
      margin-bottom: 0;
    }

    span {
      a {
        display: inline-block;
        position: relative;

        font-weight: 500;
        color: #fff;
        text-decoration: none;

        &:after {
          content: "";
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: #2a7de1;
          transform-origin: bottom right;
          transition: transform 0.25s ease-out;
        }

        &:hover:after {
          transform: scaleX(1);
          transform-origin: bottom left;
        }
      }
    }
  }
}

@media only screen and (max-width: 1200px) {
  footer {
    .upper-footer-wrapper {
      flex-wrap: wrap;

      .left-footer {
        width: 100%;
        padding: 7%;

        p {
          font-size: 16px;
          margin: 20px 0 40px;
        }
      }

      .center-footer {
        width: 100%;
        border-top: 1px solid #fff;
        border-bottom: 1px solid #fff;
        border-left: none;
        border-right: none;

        .col-6 {
          padding: 5%;

          &:first-child {
            border-right: 1px solid #fff;
          }
        }
      }

      .right-footer {
        width: 100%;
        padding: 50px 120px 50px 80px;

        h4,
        p {
          text-align: center;
        }
      }
    }

    .lower-footer {
      flex-direction: column-reverse;
      align-items: center;
      gap: 30px;
    }
  }
}

@media only screen and (max-width: 767px) {
  footer {
    .upper-footer-wrapper {
      .left-footer {
        img {
          width: 120px;
        }

        p {
          font-size: 12px;
          margin: 0 0 20px;
        }

        .social {
          gap: 10px;
        }
      }

      .center-footer {
        .col-6 {
          padding: 40px 20px 50px;
        }

        h4 {
          font-size: 24px;
        }
      }

      .right-footer {
        padding: 40px 20px;
      }
    }
  }
}
</style>
